import { Cta_, device, H5, Main_Plain, P } from 'monica-alexandria';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { hasQuestAdSubscription } from '../utils';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../services/authentication/Guard';

const buttonEffectOne = keyframes`
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const buttonEffectTwo = keyframes`
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;


const HomeHeader = styled.div`
  padding: 4rem ;

  @media ${device.sm} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const HomeHeaderHero = styled.div`

`;

const HomeHeaderCard = styled.header`
  padding: 4rem;
  border-radius: 2rem;
  box-shadow: ${p => p.theme.out};
  background: ${p => p.theme.low};

   @media ${device.sm} {
    padding: 2rem;
  }
`;

const HomeHeaderTop = styled.div`
  text-align: center;
  @media ${device.sm}{
     p{
     font-size: 1.4rem;
  }
  }
 
`;

const HomeHeaderText = styled.div`
  text-align: center;
  h1 {
    font-size: 6rem;
    margin: 1rem 0 4rem 0;
  }


  @media ${device.xl}{
    h1{
      font-size: 3rem;
    }
  }
  @media ${device.md}{
    h1{
      font-size: 2.5rem;
    }
  }
`;

const HomeHeaderMain = styled.div`
  text-align: center;

  @media ${device.sm}{
     h5{
      font-size: 1.4rem;
    }

  }
`;

const HomeHeaderActions = styled.div`
  margin-top: 4rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;

  @media ${device.xs}{
    flex-direction: column;

    a{
      width: 100%;
      span{
        width: 100%;
        text-align: center;
      }
    }
  }
`;

const HomeHeaderBook = styled.div`
   .MainPlain{
    border: 2px dashed var(--main);
    border-radius: var(--normalRadius);
    transition: all 0.5s;

    &:hover {
      border: 2px solid var(--mainLight);
    }

   }

`;

const HomeHeaderCta = styled.div`
  span {
    font-size: 2.2rem;
  }
`;

const HomeHeaderImg = styled.img`
  width: 100%;
  max-width: 100rem;
  margin: 5rem auto 0 auto;
  display: block;
  border-radius: 2rem;
`;

const HomeHeaderVideoWrapper = styled.div`
 // display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 50;
  background: var(--blackOverlay);
`;

const HomeHeaderVideo = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 16/9;
  max-width: 100rem;
  width: calc(100% - 8rem);
  overflow: hidden;
  border-radius: 2rem;
`;

const HomeHeaderVideoClose = styled.div`
  span {
    color: var(--white);
  }
`;


const HomeHeaderVisual = styled.div`
  position: relative;
`;



const HomeHeaderPlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover .HomeHeaderPlayEffect {
    display: none;
  }
`;

const HomeHeaderPlayButton = styled.div`
  background: var(--main);
  border-radius: 2rem;
  position: relative;
  padding: 2rem;
  img {
    width: 3rem;
  }
`;

const HomeHeaderPlayEffectOne = styled.div`
  border-radius: 2rem;
  height: 100%;
  width: 100%;
  background: var(--main);
  position: absolute;
  animation: ${buttonEffectOne} 2s infinite;
`;

const HomeHeaderPlayEffectTwo = styled.div`
  border-radius: 2rem;
  height: 100%;
  width: 100%;
  background: var(--main);
  position: absolute;
  animation: ${buttonEffectTwo} 2s infinite 0.5s;
`;



export const Home_Header = (props) => {
  const {t} = useTranslation();
  const [playVideo,setPlayVideo] = useState(false)
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  return (
    <HomeHeader>
    <HomeHeaderCard>
      <HomeHeaderHero>
          <HomeHeaderTop>
            <P>{t("Welcome to the world's 1st Meta Ads automation tool")}.</P>
          </HomeHeaderTop>
          <HomeHeaderText>
            <h1>
            {t("Run Ads effortlessly on Facebook and Instagram")}
              <br/> {t("without any marketing knowledge")}.
            </h1>
          </HomeHeaderText>
          <HomeHeaderMain>
            <H5>
            {t("Become your own digital marketer. Ideal for small businesses, freelancers, solopreneurs & business owners")}.
            </H5>
          </HomeHeaderMain>
          <HomeHeaderActions>
            <Cta_
              text={t('Advertise now')}
              size='large'
              onClick={props.onStart}
            />
            
        
            <HomeHeaderBook>
              {/* <span>Book a demo</span> */}
              <Main_Plain 
                text={t('Book a demo')}
                size='large'
                onClick={props.onBook}
               // onClick={()=>console.log('geia')}
                
              />
            </HomeHeaderBook>
          </HomeHeaderActions>
          <HomeHeaderVisual>
            <HomeHeaderImg
              src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/header/Compare.svg"
              alt="Doitforme automated ads interface"
            />
            {/* <HomeHeaderPlay onClick={()=>setPlayVideo(true)}>
              <HomeHeaderPlayEffectOne></HomeHeaderPlayEffectOne>
              <HomeHeaderPlayEffectTwo></HomeHeaderPlayEffectTwo>
              <HomeHeaderPlayButton>
                <img
                  src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/icons/Play.svg"
                  alt="Play button"
                />
              </HomeHeaderPlayButton>
             
            </HomeHeaderPlay> */}
            {playVideo ? 
              <HomeHeaderVideoWrapper onClick={()=>setPlayVideo(false)}>
                <HomeHeaderVideo>
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/Wn7PbcAiGas?si=KcG-Gh4TvNRBa_3C&autoplay=1&mute=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  />
                </HomeHeaderVideo>
              </HomeHeaderVideoWrapper>
            :null}
          </HomeHeaderVisual>
      </HomeHeaderHero>
    </HomeHeaderCard>
  </HomeHeader>
  )
}
