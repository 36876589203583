import { Cta_, device, H2, IconSvg, P } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'


const adsfeed = keyframes`
  0%{
        transform: translateY(0%);
    }

    10%{
        transform: translateY(-20%);
    }

    15%{
        transform: translateY(-20%);
    }

    25%{
        transform: translateY(-45%);
    }

    30%{
        transform: translateY(-45%);
    }
    
    45%{
        transform: translateY(-70%);
    }

    50%{
        transform: translateY(-70%);
    }

    70%{
        transform: translateY(-40%);
    }

    75%{
        transform: translateY(-40%);
    }

    80%{
        transform: translateY(-20%);
    }

    85%{
        transform: translateY(-20%);
    }

    95%{
        transform: translateY(0%);
    }

    100%{
        transform: translateY(0%);
    }
`;


const HomeAds = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    padding: 0rem 4rem;


    @media ${device.md}{
        grid-template-columns: 1fr;
    }
`

const HomeAdsLeft = styled.div``

const HomeComplicated = styled.div`
    padding: 8rem 0;

    .Cta{
        margin-top: 4rem;
    }
`
const HomeComplicatedLeft = styled.div``

const HomeComplicatedList = styled.ul`
    li{
        margin-bottom: 2rem;
        display: flex;
        gap: 2rem;
        align-items: flex-start;

        svg{
            width: 2rem;
            margin-top: .5rem;
            fill: ${p => p.theme.color};
        }

        p{
            line-height: 1.3;
        }
    }
`

const HomeAdsRight = styled.div`

`
const HomeAdsMobile = styled.div`
 position: relative;
    height: 60rem;
    width: 100%;
    max-width: 35rem;
    border-radius: 2rem;
    background-color: var(--grey);
    margin: 0 auto;
    overflow: hidden;
    box-shadow: var(--outShadowFocus);

    @media ${device.md}{
        width: 30rem;
    }

    @media ${device.sm}{
        width: 25rem;
    }
`
const HomeAdsFeed = styled.div`
    width: 100%;
    height: 100%;
    
    img{
        width: 100%;
        animation: ${adsfeed} 40s infinite ease-in-out;
    }
`
const HomeAdsMobileFrame = styled.div``


export const Home_Ads = (props) => {
    const {t} = useTranslation();
  return (
    <HomeAds>
        <HomeAdsLeft>
            <HomeComplicated>
                <HomeComplicatedLeft>
                    <H2>{t("Create complicated marketing strategies that get results in minutes.")}</H2>
                    <HomeComplicatedList>
                        <li>
                            <IconSvg Icon="Check"/>
                            <P>{t("You can have expert marketing strategies designed for your business by senior marketers.")}</P>
                        </li>
                        <li>
                            <IconSvg Icon="Check"/>
                            <P>{t("Our app optimizes social media ad campaigns by analyzing your answers on the questionnaires.")}</P>
                        </li>
                        <li>
                            <IconSvg Icon="Check"/>
                            <P>{t("Depending on your main sales objective & budget Questad tailors strategies to reach your target audience and goals effectively.")}</P>
                        </li>
                        <li>
                            <IconSvg Icon="Check"/>
                            <P>{t("Using advanced algorithms, it considers factors like audience demographics and past performance to maximize your ad spend.")}</P>
                        </li>
                       
                    </HomeComplicatedList>
                </HomeComplicatedLeft>
                <Cta_ text={t("Start creating ads")} size='large' onClick={props.onStart}/>
            </HomeComplicated>
        </HomeAdsLeft>
        <HomeAdsRight>
            <HomeAdsMobile>
                <HomeAdsFeed>   
                    <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/realAds/AdsFeed.svg" alt=""/>
                </HomeAdsFeed>
                <HomeAdsMobileFrame/>
            </HomeAdsMobile>
        </HomeAdsRight>
    </HomeAds>
  )
}
