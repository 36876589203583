import { Actions_, AlertContext, Builder_, Confirmation_, Focus_, Grey_, Input_, Main_, Main_Text_Loader, Steps_Helper } from 'monica-alexandria';
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import styled from 'styled-components'
import { alertMessage } from '../../../../utils/messagesAlerts';


import q  from '../../../../services/api/Quests';
import { PaymentTip } from '../../../../styles/Layout';
import Subscription_Topup_Summary from './Subscription_Topup_Summary';
const clients = require('../../../../config/index').config

const SubscriptionTopUp = styled.div``



export default function Subscription_Topup(props) {

    const {t} = useTranslation();
    const {lang} = useParams();
    const [loading,setLoading] = useState(false);
    const [confirmation ,setConfirmation] = useState(false);
    const [iAmSure ,setIamSure] = useState(false);
    const [newBudget, setNewBudget] = useState('');
    const [taxedBudget, setTaxedBudget] = useState('');
    const { alerts, setAlerts } = useContext(AlertContext);
    const [messages, setMessages] = useState({
        errors: [], 
        helpers: [],
        successes: [],
    });

    const choice_yes = () => {
        setIamSure(true)
    }

    const choice_no = () => {
        setConfirmation(false);
        setIamSure(false);
    }

    const [showSummary, setShowSummary] = useState(false)


    const handleBudgetChange = (e) => {
        // Check if the entered value is a valid number (float or integer)

        let helpers = [];
        let successes = [];

        const inputValue = e.target.value; 

        if (inputValue != '') {
            if(inputValue < props.adspend + 1 )  helpers.push(t('New adspend must be more than current')) 
            else if (inputValue >= 50) successes.push(t('Minimum adspend is 50€/month'))

            if (!Number.isInteger(Number(inputValue))) helpers.push(t('Do not include decimals')) 
            else successes.push(t('Do not include decimals'))

            if(inputValue > 10000 )  helpers.push(t('Maximum adspend is 10000€/month')) 
            else if (inputValue <= 10000) successes.push(t('Maximum adspend is 10000€/month'))
        }
            setNewBudget(inputValue.trim());
            setMessages({...messages, helpers: helpers, successes: successes})
      };


      const calculateTax = () => {
        console.log('customerId',props?.customerId, newBudget );
        
        let budgeterrors = [];  
    
        if (messages.helpers?.length !== 0) {
            budgeterrors = messages.helpers;
             setNewBudget('')
             setMessages({ ...messages, errors: budgeterrors, helpers: [] })
        }
        else{
            q.calculateTaxAmount(props?.customerId, newBudget)
            .then(tax_res => {

                console.log("res tax_res", tax_res)
                console.log("props?.isOldBudgetUser", props?.isOldBudgetUser)

                setTaxedBudget( tax_res?.data?.tax)
                setShowSummary(true)
        })}
      }

      
      const proceed_with_new_budget = () => {

        let budgeterrors = [];  
    
        if (messages.helpers?.length !== 0) {
            budgeterrors = messages.helpers;
             setNewBudget('')
        }
        
        if (newBudget === '')  alertMessage(alerts, setAlerts, (t('Monthly budget must not be empty.')), 'Error')
        else if (newBudget < props.totalBudget + 1 || !Number.isInteger(Number(newBudget)) ) {
            setMessages({ ...messages, errors: budgeterrors, helpers: [] })
        } else {
            setLoading(true);
            let totalAmount;
              if (props?.isOldBudgetUser) {
                totalAmount = newBudget;
            }
                else {
                    totalAmount = taxedBudget.totalAmount;
                }
            q.topUpQuestBudgetV2(props?.selectedQuestId, totalAmount , props?.selectedSubId)
            .then( res => {
                console.log('res top up budget', res)
            
                if (res?.data?.code === "Q2012") {
                    setLoading(false);
                    props.onSubscriptionTopupCancel();
                    alertMessage(alerts, setAlerts, (t('New budget was set for quest.')), 'Success')

                    props?.onTopupSuccess();
                    //window.location.reload();
                }
            })
            
            .catch(err =>console.log(err))
                setLoading(false);
            }
        }
      console.log("messeges", messages)
      console.log("taxedBudget", taxedBudget)

    /* IF you remove the confirmation popup, 
     this useffect is useless you can delete it */
    useEffect(() => {
        if (iAmSure) {
            setLoading(true);
            q.topUpQuestBudgetV2(props?.selectedQuestId, newBudget, props?.selectedSubId)
                    .then( res => {
                        console.log('res top up budget', res)
                    
                        if (res?.data?.code === "Q2012") {
                            setLoading(false);
                            props.onSubscriptionTopupCancel();
                            alertMessage(alerts, setAlerts, (t('New budget was set for quest.')), 'Success')
                            window.location.reload();
                        }
                    })
            }
    }, [iAmSure])


    console.log('old budget', props.isOldBudgetUser);
    console.log('totalBudget', props.totalBudget);
    console.log('totalAmount', props.totalAmount);
    
  if (loading) return <Main_Text_Loader text={t('Applying Changes...')}/>
  return (
    <SubscriptionTopUp>
                {confirmation  ?
         <Confirmation_
                message={t("Are you sure about increasing your budget? Your new budget will be calculated like this") +": "+ newBudget +"€"+ ' + VAT = ' + (parseFloat(newBudget) + parseFloat(newBudget*(24/100)))+"€"}
                no={t("No")}
                onNo={() => choice_no()}
                onYes={()=> choice_yes()}
                yes={t("Yes")}
            /> : <></>}
        {!showSummary? 
        <Builder_ title={t("Set quest's Adspend")}>
            <Input_ 
                placeholder={t("Quest's Adspend")}
                value={newBudget}
                onChange = {handleBudgetChange}
                errors={messages?.errors}
                helpers={messages?.helpers}
                successes={messages?.successes}
            />
        </Builder_>
        
        :<Subscription_Topup_Summary 
            currency='eur'
            amount={taxedBudget}
            subscriptionNextPayment={props.subscriptionNextPayment}
        /> }

    {props.isOldBudgetUser?
        <Actions_>
            <Grey_ iconLeft={"Xicon"} text={t("Cancel")} onClick={()=>props.onSubscriptionTopupCancel()}/>
            <Focus_ iconRight="Next" text={t("Set budget")} onClick={()=> proceed_with_new_budget()}/>
        </Actions_>
    :
        <Actions_>
            <Grey_ iconLeft={showSummary ? "Back" :"Xicon" } text={showSummary ? t("Back") : t("Cancel") } onClick={()=>showSummary ? setShowSummary(false) : props.onSubscriptionTopupCancel()}/>
            <Focus_ iconRight="Next" text={showSummary ? t("Set budget") : t("Next") } onClick={()=>showSummary ? proceed_with_new_budget() : calculateTax()}/>
        </Actions_>
    }
    </SubscriptionTopUp>
  )
}
