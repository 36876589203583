import axios from 'axios';
import store from '../../redux/store';

const protocol = process.env.REACT_APP_DAILYPROFIT_BACKEND_PROTOCOL;
const url = process.env.REACT_APP_DAILYPROFIT_BACKEND_HOST;
const port = process.env.REACT_APP_DAILYPROFIT_BACKEND_PORT;

const getCampaignById = (campaignId, token) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/meta/getCampaignById`, {
            campaignId: campaignId,
            token: token
        })
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET CAMPAIGN BY ID');
            resolve(false);
        })
    })
}

const getConnectedFacebookPages = (token, next) => {

    return new Promise((resolve, reject) => {

        let data = {
            token: token
        }

        if (next && next !== 'initial') data.next = next;

        axios.post(`${protocol}${url}${port}/meta/getConnectedFacebookPages`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET CONNECTED FACEBOOK PAGES');
            resolve(false);
        })
    })
}

const getPixels = () => {

    const access_token = store.getState().metaSource?.facebook_user?.accessToken;

    return new Promise((resolve, reject) => {

        let data = {
            token: access_token
        }

        axios.post(`${protocol}${url}${port}/meta/getPixels`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET PIXELS');
            resolve(false);
        })
    })
}

const getProductCatalogs = () => {

    const access_token = store.getState().metaSource?.facebook_user?.accessToken;

    return new Promise((resolve, reject) => {

        let data = {
            token: access_token
        }

        axios.post(`${protocol}${url}${port}/meta/getProductCatalogs`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET PRODUCT CATALOGS');
            resolve(false);
        })
    })
}

const getProductSets = (catalogId) => {

    const access_token = store.getState().metaSource?.facebook_user?.accessToken;

    return new Promise((resolve, reject) => {

        let data = {
            catalogId: catalogId,
            token: access_token
        }

        axios.post(`${protocol}${url}${port}/meta/getProductSets`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET PRODUCT SETS');
            resolve(false);
        })
    })
}

const savePixel = (pixelId, pixelName, questId) => {

    const access_token = store.getState().metaSource?.facebook_user?.accessToken;

    return new Promise((resolve, reject) => {

        let data = {
            pixelId: pixelId,
            pixelName: pixelName,
            questId: questId,
            token: access_token
        }

        axios.post(`${protocol}${url}${port}/meta/savePixel`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('SAVE PIXEL');
            resolve(false);
        })
    })
}

const saveCatalog = (catalogId, catalogName, catalogBusinessId, questId) => {

    const access_token = store.getState().metaSource?.facebook_user?.accessToken;

    return new Promise((resolve, reject) => {

        let data = {
            catalogId: catalogId,
            catalogName: catalogName,
            catalogBusinessId: catalogBusinessId,
            questId: questId,
            token: access_token
        }

        axios.post(`${protocol}${url}${port}/meta/saveCatalog`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('SAVE CATALOG');
            resolve(false);
        })
    })
}

const sharePixelAdmin = (pixelId) => {

    return new Promise((resolve, reject) => {

        let data = {
            pixelId: pixelId,
        }

        axios.post(`${protocol}${url}${port}/meta/sharePixelAdmin`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('SHARE PIXEL ADMIN');
            resolve(false);
        })
    })
}



const getSearchInterests = (data) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/meta/getSearchInterests`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET SEARCH INTERESTS');
            resolve(false);
        })
    })
}

const getSearchLocations = (data) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/meta/getSearchLocations`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET SEARCH LOCATIONS');
            resolve(false);
        })
    })
}

const getCoordinatesForLocations = (data) => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/meta/getCoordinatesForLocations`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET COORDINATES FOR LOCATIONS');
            resolve(false);
        })
    })
}

const performExtensiveTask = (data) => {

    return new Promise((resolve, reject) => {

        data = {
            "url": "some-url",
            "thumbnail": "some-thumbnail-url",
            "active": true,
            "nestedJson": {
                nested: true,
                shouldShow: true,
                shouldHide: false
            }
        }

        axios.post(`${protocol}${url}${port}/meta/extensiveTask`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('PERFORM EXTENSIVE TASK');
            resolve(false);
        })
    })
}

const getConnectedInstagramAccount = (pageAccessToken, facebookPageId) => {

    return new Promise((resolve, reject) => {

        let data = {
            pageAccessToken: pageAccessToken,
            facebookPageId: facebookPageId
        }

        axios.post(`${protocol}${url}${port}/meta/getConnectedInstagramAccount`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET CONNECTED INSTAGRAM ACCOUNT');
            resolve(false);
        })
    })
}


const createCampaign = () => {

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/meta/createCampaign`, {data: {}}, {})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('CREATE CAMPAIGN')
            resolve(false);
        })
    })
}

const editAdCreative = (questId, mode) => {
    
    let data = {
        questId: questId,
        mode: mode
    }

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/meta/editAdCreative`, {data: data}, {})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('EDIT AD CREATIVE')
            resolve(false);
        })
    })
}

const editAdDemographics = (questId) => {
  
    let data = {
        questId: questId
    }

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/meta/editAdDemographics`, {data: data}, {})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('EDIT AD DEMOGRAPHICS')
            resolve(false);
        })
    })
}

const getPreview = (fbPage, accessToken, questId) => {

    let data = {
      id: fbPage,
      accessToken: accessToken,
      questId: questId
    }

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/meta/getAdPreview`, {data: data}, {})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET PREVIEW')
            resolve(false);
        })
    })
}

const stopAds = (questId,userId) => {

    let data = {
        status: "QUESTAD STOP",
        quest: questId,
        userId: userId,
        start_date: Date.now(),
        end_date: Date.now()

    }

    return new Promise((resolve, reject) => {

        axios.post(`${protocol}${url}${port}/meta/toggleAds`, data, {})
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET PREVIEW')
            resolve(false);
        })
    })
}


const getOwnedAdAccounts = (business, next) => {

    const access_token = store.getState().metaSource?.facebook_user?.accessToken;
    console.log('HERE', business);
    
    return new Promise((resolve, reject) => {

        let data = {
            business_id:business,
            token: access_token,
            next
        }

        console.log('DATA', data);
        
        axios.post(`${protocol}${url}${port}/meta/getOwnedAdAccounts`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET OWNED AD ACCOUNTS');
            resolve(false);
        })
    })
}


const getClientAdAccounts = (business, next) => {

    const access_token = store.getState().metaSource?.facebook_user?.accessToken;
    console.log('HERE', business);
    
    return new Promise((resolve, reject) => {

        let data = {
            business_id:business,
            token: access_token,
            next
        }

        console.log('DATA', data);
        
        axios.post(`${protocol}${url}${port}/meta/getClientAdAccounts`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET CLIENT AD ACCOUNTS');
            resolve(false);
        })
    })
}

const getConnectedBusinesses = () => {

    const access_token = store.getState().metaSource?.facebook_user?.accessToken;

    return new Promise((resolve, reject) => {

        let data = {
            token: access_token,
            after: false
        }

        axios.post(`${protocol}${url}${port}/meta/getConnectedBusinesses`, data)
        .then(res => {
            resolve(res);
        })
        .catch(() => {
            console.error('GET OWNED AD ACCOUNTS');
            resolve(false);
        })
    })
}

const metaEndpoints = {
    getCampaignById,
    getConnectedFacebookPages,
    getConnectedInstagramAccount,
    createCampaign,
    getPreview,
    editAdCreative,
    editAdDemographics,
    stopAds,
    getPixels,
    getSearchInterests,
    getSearchLocations,
    performExtensiveTask,
    savePixel,
    saveCatalog,
    sharePixelAdmin,
    getProductCatalogs,
    getProductSets,
    getCoordinatesForLocations,
    getOwnedAdAccounts,
    getClientAdAccounts,
    getConnectedBusinesses
}

export default metaEndpoints;