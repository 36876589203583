// This page makes sign in mandatory.
// Any page that uses this page as as a parent in Router, is a protected page.
// That means that only logged-in users can access it.
// The decision whether the user should navigate to login is made here.
// GuardedRoute is informed (via shouldRedirect prop) and acts accordingly.

import axios from 'axios'
import { React, useEffect, useState, createContext } from 'react';
import { GuardedRoute } from './Guard';
import store from '../../redux/store';
import { useNavigate } from 'react-router-dom';


////////////////////////////////////////////////////////////////////
// Informs GuardedRoute that user must log in before continuing  ///
////////////////////////////////////////////////////////////////////
export const Protector = ({role, children, checkAgencyClient}) =>  {

    const navigate = useNavigate();
    const [mutex, setMutex] = useState(false)

    const login = () => {
        window.FB.login(
            function (response) {
                if (response.status === 'connected') {
                    store.dispatch({
                        type: 'SAVE_FACEBOOK_USER',
                        payload: {
                            userID: response.authResponse.userID,
                            accessToken: response.authResponse.accessToken,
                        },
                    });
                    
                    navigate(0); 
                }
            },
            {
                scope: 'ads_management,business_management,ads_read,pages_show_list, pages_read_engagement,read_insights, pages_manage_ads, pages_manage_metadata, leads_retrieval, catalog_management',
                extras: {
                    setup: {
                        external_business_id: '12345',
                        timezone: 'Europe/Athens',
                        currency: 'EUR',
                        business_vertical: 'ECOMMERCE',
                    },
                    business_config: {
                        business: {
                            name: 'Questad Doitforme',
                        },
                    },
                },
            }
        );
    };

    useEffect(() => {
        axios.interceptors.response.use(response => {
            // Scenario where requests happen simultaneously. We do not want multiple popup windows
            if (response?.data?.code == 'C5190') setMutex(true);
            return response;
        })
    },[])


    useEffect(() => {
        if (mutex && window?.FB) login();
    },[mutex])

    return (
        <GuardedRoute shouldRedirect={true} role={role} checkAgencyClient={checkAgencyClient}>
            {children}
        </GuardedRoute>
    );
}
