import React from 'react'
import styled from 'styled-components'
import Subscription_Card from './Subscription_Card'
import { Builder_, Input_, Slider_ } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'

const DashboardSubscription = styled.div``

const DashboardSubscriptionTopUp = styled.div``

export default function Dashboard_Subscription(props) {

  const {t} = useTranslation();

  return (
    <DashboardSubscription>
        <Subscription_Card
          isPocketAgencyUser={props.isPocketAgencyUser}
          amountPaid={props.amountPaid}
          adspend={props.adspend}
          amountTopUp={props.amountTopUp}
          subscriptionStatus={props.subscriptionStatus}
          subscriptionNextPayment={props.subscriptionNextPayment}
          onSubscriptionTopup={props.onSubscriptionTopup}
          onSubscriptionDetails={props.onSubscriptionDetails}
          onSubscriptionCancel={props.onSubscriptionCancel}
          subscriptionCancelOnPeriodEnd={props.subscriptionCancelOnPeriodEnd}
          onSubscriptionRenew={props?.onSubscriptionRenew}
          customerId={props.customerId}
          isOldBudgetUser={props.isOldBudgetUser} 
         
          />
    </DashboardSubscription>
  )
}
