import { Cta_, device, H2, H3 } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const HomeDevices = styled.section`
 
`
const HomeDevicesGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    grid-gap: 4rem;

    @media ${device.lg}{
        grid-template-columns: 1fr;
        display: flex;
        flex-direction: column-reverse; 
        align-items: center;
        gap: 4rem; 
    }


`
const HomeDevicesLeft = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4rem;


    @media ${device.lg}{
      padding-top: 6rem;
    }

`
const HomeDevicesBox = styled.div`
    box-shadow: ${p=>p.theme.out};
    border-radius: var(--normalRadius);
    overflow: hidden;
    background: ${p=>p.theme.background};
    background: ${p=>p.theme.low};
`
const HomeDevicesContent = styled.div`
    background: ${p=>p.theme.low};
     padding: 2rem 4rem;
`
const HomeDevicesImg = styled.div`
    padding: 4rem;

     img{
        width: 100%;
        height: 100%;
        object-fit: contain;
     }
`

const HomeDevicesTabletStories = styled.img`
    max-width: 40rem;
    display: block;
    margin: 0 auto;
`

const HomeDevicesRight = styled.div`
    position: sticky;
    top: 8rem;
    background: var(--gloomDark);
    padding: 4rem;
    border-radius: 2rem;

    h2{
        color: var(--white)
    }

    @media ${device.lg}{
        /* padding-bottom: 6rem; */
        position: relative;
      
    }

    .Cta{
        width: 100%;
        justify-content: center;
        margin-top: 4rem;
    }
    

`

export const Home_Devices = (props) => {
    const {t} = useTranslation();

  return (
    <HomeDevices>
        <HomeDevicesGrid>
            <HomeDevicesLeft>
                <HomeDevicesBox>
                    <HomeDevicesContent>
                        <H3>Instagram Reels</H3>
                    </HomeDevicesContent>
                    <HomeDevicesImg>
                         <HomeDevicesTabletStories src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/device/IReels.svg" alt=""/>
                    </HomeDevicesImg>
                </HomeDevicesBox>
                <HomeDevicesBox>
                    <HomeDevicesContent>
                        <H3>Facebook Stories</H3>
                    </HomeDevicesContent>
                    <HomeDevicesImg>
                         <HomeDevicesTabletStories src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/device/FStories.svg" alt=""/>
                    </HomeDevicesImg>
                </HomeDevicesBox>

                <HomeDevicesBox>
                    <HomeDevicesContent>
                        <H3>Facebook Feed</H3>
                    </HomeDevicesContent>
                    <HomeDevicesImg>
                         <HomeDevicesTabletStories  src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/device/FFeed.svg" alt=""/>
                    </HomeDevicesImg>
                </HomeDevicesBox>

                <HomeDevicesBox>
                    <HomeDevicesContent>
                        <H3>Instagram Stories</H3>
                    </HomeDevicesContent>
                    <HomeDevicesImg>
                         <HomeDevicesTabletStories  src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/device/IStories.svg" alt=""/>
                    </HomeDevicesImg>
                </HomeDevicesBox>

                <HomeDevicesBox>
                    <HomeDevicesContent>
                        <H3>Instagram Feed</H3>
                    </HomeDevicesContent>
                    <HomeDevicesImg>
                         <HomeDevicesTabletStories  src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/device/IFeed.svg" alt=""/>
                    </HomeDevicesImg>
                </HomeDevicesBox>
            </HomeDevicesLeft>

            <HomeDevicesRight>
                <H2>{t("Run Ads in all Meta placements.")}<br/>{t("In any device, from any device")}</H2>
                <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/tarzan/device/MonitorFeed.svg" alt=""/>
                <Cta_ onClick={props.onStart} text={t("Get started")} size='large'/>
            </HomeDevicesRight>
        </HomeDevicesGrid>
    </HomeDevices>
  )
}
